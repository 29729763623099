<template>
  <div :class="[$style.container, {[$style.containerPaddingTop]: !hasSectionTitle}]">
    <div :class="[$style.content, {[$style.contentAlignmentReverse]: content.contentAlignment === 'right', [$style.hasSidebar]: hasSidebar}]">
      <div :class="$style.textContainer">
        <lv-heading
          v-if="content.title"
          tag="h3"
          level="3"
          :style="{color: standardComponentTextColor}"
          version2
        >
          {{ content.title }}
        </lv-heading>
        <aw-text-block
          :class="$style.contentText"
          :content="content"
          :analytics-banner-interaction="analyticsBannerInteraction"
          :analytics-measurable="analyticsMeasurable"
        />
        <aw-buttons
          :content="content"
          :button-attrs="{shrink: true, stretch: true}"
          :show-title="false"
          :analytics-banner-interaction="analyticsBannerInteraction"
          :analytics-measurable="analyticsMeasurable"
        />
      </div>
      <aw-video-card
        v-if="content.media && content.media.video"
        :media="content.media"
        :media-title="content.title"
        :class="$style.video"
      />
    </div>
  </div>
</template>

<script>
  import { LvHeading } from '~~/common/components/loginet-vue-shop/index.mjs';
  import standardComponentsMixin from '~~/common/mixins/standardComponentsMixin';
  import analyticsBannerInteractionMixin from '~~/common/mixins/analyticsBannerInteractionMixin';
  import { defineAsyncComponent } from 'vue';

  export default {
    name: 'AwDividedVideo',
    components: {
      LvHeading,
      AwVideoCard: defineAsyncComponent(() => import('./AwVideoCard')),
      AwTextBlock: defineAsyncComponent(() => import('./AwTextBlock')),
      AwButtons: defineAsyncComponent(() => import('./AwButtons')),
    },
    mixins: [
      standardComponentsMixin,
      analyticsBannerInteractionMixin,
    ],
    props: {
      content: {
        type: Object,
        required: true,
      },
      hasSidebar: {
        type: Boolean,
        default: false,
      },
      hasSectionTitle: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.container {
  padding-bottom: 20px;
  text-align: left;

  @include tablet(min) {
    padding-bottom: 37px;
  }

  @include desktop-small(min) {
    padding-bottom: 40px;
  }

  &PaddingTop {
    padding-top: 20px;

    @include tablet(min) {
      padding-top: 37px;
    }

    @include desktop-small(min) {
      padding-top: 40px;
    }
  }

  .content {
    display: grid;
    align-items: start;
    justify-content: center;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "text text" "video video";
    gap: 8px;
    row-gap: 24px;

    @include tablet(min) {
      gap: 24px;
      grid-template-columns: repeat(8, 1fr);
      grid-template-areas: "text text text text text text text text" "video video video video video video video video";
    }

    @include desktop-small(min) {
      gap: 32px;
      grid-template-columns: repeat(12, 1fr);
      grid-template-areas: "text text text text text text text video video video video video";
    }

    @include desktop-medium(min) {
      gap: 40px;
      grid-template-columns: repeat(10, 1fr);
      grid-template-areas: "text text text text text text video video video video";

      &.hasSidebar {
        grid-template-columns: repeat(8, 1fr);
        grid-template-areas: "text text text text text video video video";
      }
    }

    &AlignmentReverse {
      @include mobile(max) {
        grid-template-areas: "video video" "text text";
      }

      @include tablet(only) {
        grid-template-areas: "video video video video video video video video" "text text text text text text text text";
      }

      @include desktop-small(min) {
        grid-template-areas: "video video video video video text text text text text text text";
      }

      @include desktop-medium(min) {
        grid-template-areas: "video video video video text text text text text text";

        &.hasSidebar {
          grid-template-columns: repeat(8, 1fr);
          grid-template-areas: "video video video text text text text text";
        }
      }
    }

    .textContainer {
      grid-area: text;
      display: flex;
      flex-direction: column;
      gap: 12px;

      @include tablet(min) {
        gap: 20px;
      }
    }

    .video {
      grid-area: video;
    }
  }
}
</style>
