<template>
  <div :class="$style.container">
    <div :class="$style.border" />
    <div :class="$style.contentContainer">
      <div :class="[$style.content, {[$style.contentHasSidebar]: hasSidebar}]">
        <div :class="$style.contentTitle">
          <lv-heading
            v-if="content.title"
            tag="h2"
            level="3"
            :style="{color: standardComponentTextColor}"
            version2
          >
            {{ content.title }}
          </lv-heading>
          <lv-image
            v-if="standardComponentContentIcon"
            auto-height
            :sources="standardComponentContentIconSource"
            :alt="standardComponentContentIcon.alt"
            :class="$style.contentIcon"
          />
          <lv-icon
            v-else
            name="birdy-48"
            :size="48"
            :class="$style.contentIcon"
          />
        </div>

        <aw-text-block
          :class="$style.contentText"
          :content="content"
          :analytics-banner-interaction="analyticsBannerInteraction"
          :analytics-measurable="analyticsMeasurable"
        />
      </div>
      <div :class="$style.quoteMark">
        <lv-icon name="quote-mark-144" :size="144" :class="$style.quoteMarkIcon" />
      </div>
    </div>
  </div>
</template>

<script>
  import { LvHeading, LvImage, LvIcon } from '~~/common/components/loginet-vue-shop/index.mjs';
  import standardComponentsMixin from '~~/common/mixins/standardComponentsMixin';
  import analyticsBannerInteractionMixin from '~~/common/mixins/analyticsBannerInteractionMixin.js';
  import { defineAsyncComponent } from 'vue';

  export default {
    name: 'AwDividedTitleAndText',
    components: {
      LvHeading,
      LvImage,
      LvIcon,
      AwTextBlock: defineAsyncComponent(() => import('./AwTextBlock')),
    },
    mixins: [
      standardComponentsMixin,
      analyticsBannerInteractionMixin,
    ],
    props: {
      content: {
        type: Object,
        required: true,
      },
      hasSidebar: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.container {
  display: flex;
  flex-direction: row;
  width: 100%;

  .content {
    position: relative;
    z-index: 1;
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(8, 1fr);
    grid-template-areas:
      "title title title title title title title title"
      "text text text text text text text text";

    @include tablet(min) {
      grid-template-columns: repeat(8, 1fr);
      grid-template-areas:
          "title title title text text text text text";
    }

    @include desktop-small(min) {
      gap: 32px;
    }

    @include desktop-medium(min) {
      gap: 40px;
      grid-template-columns: repeat(10, 1fr);
      grid-template-areas: "title title title title text text text text text text";

      &HasSidebar {
        grid-template-columns: repeat(8, 1fr);
        grid-template-areas:
          "title title title text text text text text";
      }
    }

    &Container {
      width: 100%;
    }

    &Text {
      font-size: 14px;
      font-style: italic;
      line-height: 20px;

      display: flex;
      align-items: center;
      padding: 0 24px 20px;
      color: $color-text-secondary;

      grid-area: text;

      @include tablet(min) {
        padding: 40px 40px 40px 0;
      }

      @include tablet(min) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    &Title {

      display: flex;
      flex-direction: column-reverse;
      justify-content: center;

      padding: 20px 24px 0;
      grid-area: title;
      gap: 20px;
      justify-self: start;

      @include tablet(min) {
        padding: 40px 0 40px 40px;
      }

      @include desktop-small(min) {
        align-items: center;
        flex-direction: row-reverse;
      }
    }

    &Icon {
      width: 48px;
      min-width: 48px;
      height: 48px;
      min-height: 48px;
      color: $color-strawberry-500;
      stroke: none;
    }
  }

  .quoteMark {
    display: flex;
    justify-content: end;
    margin-top: -128px;

    &Icon {
      width: 144px;
      height: 128px;
      stroke: none;
    }
  }

  .border {

    width: 0;
    height: 100%;
    margin-right: -4px;
    border-left: 4px solid $color-red-500;
  }
}
</style>
