<template>
  <div :class="[$style.awBarForTextAndImage, $style[`awBarForTextAndImage${content.contentAlignment}`]]">
    <div :class="$style.awBarForTextAndImageWrapper">
      <lv-grid
        type="row"
        wrap="wrap"
        gutter="xs"
        align-items="start"
        :class="$style.awBarForTextAndImageRow"
      >
        <lv-grid type="item" xs="1/1" md="1/2">
          <div :class="$style.awBarForTextAndImageContent">
            <div :class="$style.awBarForTextAndImageContentHeadingWrapper">
              <lv-heading
                v-if="content.title"
                level="5"
                tag="h2"
                :style="{color: standardComponentTextColor}"
              >
                {{ content.title }}
              </lv-heading>
            </div>
            <div
              :class="$style.awBarForTextAndImageContentBar"
              :style="{ backgroundColor: standardComponentBackgroundColor}"
            >
              <lv-image
                v-if="standardComponentContentImage && smMax"
                sizing="contain"
                :sources="standardComponentContentImageSource"
                :alt="standardComponentContentImage.alt"
                :class="$style.awBarForTextAndImageImage"
              />
              <div :class="$style.awBarForTextAndImageEmbedWrapper">
                <aw-text-block
                  :content="content"
                  :analytics-banner-interaction="analyticsBannerInteraction"
                  :analytics-measurable="analyticsMeasurable"
                />
              </div>
              <aw-buttons
                :content="content"
                :class="$style.awBarForTextAndImageContentButton"
                :button-attrs="{shrink: true, stretch: true}"
                :show-title="false"
                :analytics-banner-interaction="analyticsBannerInteraction"
              />
            </div>
          </div>
        </lv-grid>
        <lv-grid
          v-if="!smMax"
          type="item"
          xs="1/1"
          md="1/2"
        >
          <lv-image
            v-if="standardComponentContentImage"
            sizing="contain"
            :sources="standardComponentContentImageSource"
            :alt="standardComponentContentImage.alt"
            :class="$style.awBarForTextAndImageImage"
            :loading="isLazyImage ? 'lazy' : 'eager'"
          />
        </lv-grid>
      </lv-grid>
    </div>
  </div>
</template>

<script>
  import { LvHeading, LvImage, LvGrid } from '~~/common/components/loginet-vue-shop/index.mjs';
  import standardComponentsMixin from '~~/common/mixins/standardComponentsMixin';
  import analyticsBannerInteractionMixin from '~~/common/mixins/analyticsBannerInteractionMixin.js';
  import { defineAsyncComponent } from 'vue';

  export default {
    name: 'AwBarForTextAndImage',
    components: {
      LvHeading,
      LvImage,
      LvGrid,
      AwTextBlock: defineAsyncComponent(() => import('./AwTextBlock')),
      AwButtons: defineAsyncComponent(() => import('./AwButtons')),
    },
    mixins: [
      standardComponentsMixin,
      analyticsBannerInteractionMixin,
    ],
    props: {
      content: {
        type: Object,
        required: true,
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.awBarForTextAndImage {
  padding-top: 32px;
  padding-bottom: 32px;
  text-align: left;

  &Wrapper {
    max-width: 75%;
    margin: auto;
  }

  &Image {
    margin-top: -90px;
    padding-bottom: 100%;
    background: transparent;

    @include md(min) {
      width: 95%;
      margin-top: 0;
    }

    img {
      height: auto;
    }
  }

  &Content {
    &HeadingWrapper {
      margin-bottom: 50px;

      @include md(min) {
        margin-bottom: 37px;
        padding-left: 55px;
      }
    }

    &Bar {
      padding: 24px 20px;
      border-radius: 24px;

      @include md(min) {
        width: 200%;
        padding: 45px 55px;
      }

      p {
        font-size: 14px;
      }
    }
  }

  &EmbedWrapper {
    margin-bottom: 24px;

    @include md(min) {
      width: 50%;
    }
  }

  &.awBarForTextAndImageright {
    .awBarForTextAndImage {
      &Row {
        flex-flow: row-reverse;
      }

      &Content {
        &HeadingWrapper {

          @include md(min) {
            padding-right: 55px;
            padding-left: 0;
          }
        }

        &Bar {
          left: 0;

          @include md(min) {
            position: relative;
            left: calc(-100% - 10px);
            display: flex;
            align-items: flex-end;
            flex-direction: column;
          }
        }
      }
    }
  }
}

</style>
