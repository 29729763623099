import { mapState } from 'pinia';
import { colorModelToHex, webpFallback } from '~~/common/utils';
import { useUserInterfaceStore } from '~~/common/stores/userInterface';
import { getBackgroundImages } from '~~/common/utils/imageHelper.js';
import { useRuntimeConfig } from 'nuxt/app';

export default {
  props: {
    precalculatedImageOnParent: {
      type: [String, Boolean],
      default: null,
    },
  },
  inject: ['getRowIndexById'],
  data () {
    return {
      isMounted: false,
    };
  },
  computed: {
    ...mapState(useUserInterfaceStore, {
      smMax: state => state.mediaQueries['sm-max'],
      screenRange: state => state.mediaQueries,
    }),
    standardComponentContentImage () {
      return this.content?.media?.contentImage;
    },
    standardComponentContentIcon () {
      return this.content?.media?.icon;
    },
    standardComponentContentImageSource () {
      return (this.standardComponentContentImage
        ? webpFallback([{
          url: this.standardComponentContentImage.url || '',
        }])
        : []
      );
    },
    standardComponentContentIconSource () {
      return (this.standardComponentContentIcon
        ? webpFallback([{
          url: this.standardComponentContentIcon.url || '',
        }])
        : []
      );
    },
    standardComponentBackgroundImage () {
      if (typeof this.precalculatedImageOnParent === 'string') {
        return this.precalculatedImageOnParent;
      } else if (this.precalculatedImageOnParent === true) {
        return null;
      }

      const images = [
        {
          ...this.content?.media?.largeBackgroundImage,
          media: this.content?.media?.largeBackgroundImage && this.content?.media?.smallBackgroundImage ? `(min-width: ${this.$styleVariables.breakpointTablet})` : undefined,
        },
        this.content?.media?.smallBackgroundImage,
      ];

      const backgroundImages = getBackgroundImages(images);
      const imgBasedOnRes = (
        (this.isMounted
          ? !this.content.isImageMissing ? backgroundImages[0] : backgroundImages[1]
          : null
        ) ?? {
          url: '',
          alt: '',
        }
      );
      const backgroundImage = {
        url: (this.content.isWebpMissing
          ? imgBasedOnRes?.url
          : this.$addWebpPostfixIfSupported({
            url: imgBasedOnRes?.url,
            webpUrl: `${imgBasedOnRes?.url}.webp`,
          })
        ),
        alt: imgBasedOnRes?.alt,
      };
      if (this.isMounted && backgroundImage.url) {
        return `url('${backgroundImage.url}')`;
      } else {
        return null;
      }
    },
    standardComponentBackgroundColor () {
      return (this.content?.backgroundColor?.hex && this.content?.backgroundColor?.alpha)
        ? colorModelToHex(this.content.backgroundColor)
        : 'transparent';
    },
    standardComponentTextColor () {
      return (this.content?.textColor?.hex && this.content?.textColor?.alpha)
        ? colorModelToHex(this.content.textColor)
        : undefined;
    },
    isLazyImage () {
      const limit = useRuntimeConfig().public.lazyLoadedComponentsLimit;
      if (!this.getRowIndexById) {
        return true;
      }
      return this.getRowIndexById(this.content.id) >= limit;
    },
  },
  mounted () {
    this.isMounted = true;
  },
};
