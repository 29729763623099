<template>
  <div :class="$style.container">
    <div :class="$style.content">
      <div>
        <lv-heading
          v-if="content.title"
          tag="h3"
          level="3"
          :style="{color: standardComponentTextColor}"
          align="left"
          version2
        >
          {{ content.title }}
        </lv-heading>
      </div>
      <div :class="$style.videoContainer">
        <aw-video
          v-if="content.media && content.media.video"
          :video="content.media.video"
          :video-title="content.title"
          :custom-padding-bottom="videoAspectRatio"
          :youtube-padding-bottom="videoAspectRatio"
          :vimeo-padding-bottom="videoAspectRatio"
        />
      </div>
      <div :class="$style.contentText" v-html="content.contentText" />
    </div>
  </div>
</template>

<script>
  import { defineAsyncComponent } from 'vue';
  import { createVideoAspectRatio } from '~~/common/utils/modalVideo.js';
  import standardComponentsMixin from '~~/common/mixins/standardComponentsMixin';
  import { LvHeading } from '~~/common/components/loginet-vue-shop/index.mjs';

  export default {
    name: 'AwCenteredVideo',
    components: {
      AwVideo: defineAsyncComponent(() => import('./AwVideo')),
      LvHeading,
    },
    mixins: [standardComponentsMixin],
    props: {
      content: {
        type: Object,
        required: true,
      },
    },
    setup () {
      return {
        videoAspectRatio: createVideoAspectRatio(),
      };
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.container {
  .content {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;

    .videoContainer {
      padding: 16px;
      border-radius: 24px;
      background-color: $color-background-2;

      @include tablet(min) {
        padding: 20px;
      }
    }

    .contentText {
      font-size: 14px;
      line-height: 20px;

      @include tablet(min) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
</style>
