<template>
  <div :class="[$style.wrapper, $style[`wrapper${content.contentAlignment}`]]">
    <div
      :class="[$style.container, $style[`container${content.contentAlignment}`]]"
      :style="{backgroundColor: content.contentBackgroundColorOnly ? 'transparent' : standardComponentBackgroundColor}"
    >
      <lv-heading
        v-if="content.title"
        :class="[$style.title, (content.isLargeBannerCarousel ? $style.titleCarousel : $style.titleNormal)]"
        tag="h2"
        level="1"
        version2
        :style="{'text-align': textAlignment, color: standardComponentTextColor}"
      >
        {{ content.title }}
      </lv-heading>
      <lv-heading
        v-if="content.infoText"
        :class="[$style.infoText, (content.isLargeBannerCarousel ? $style.infoTextCarousel : $style.infoTextNormal)]"
        tag="div"
        level="3"
        version2
        :style="{'text-align': textAlignment, color: standardComponentTextColor}"
        regular
      >
        {{ content.infoText }}
      </lv-heading>
      <aw-text-block
        :class="[$style.text, {[$style.textBackgroundOnly]: content.contentBackgroundColorOnly}]"
        :content="content"
        :style="{
          backgroundColor: content.contentBackgroundColorOnly ? standardComponentBackgroundColor : 'transparent',
          'text-align': textAlignment,
        }"
        :analytics-banner-interaction="analyticsBannerInteraction"
      />
      <aw-buttons
        :content="content"
        :show-title="false"
        :analytics-banner-interaction="analyticsBannerInteraction"
        :analytics-measurable="analyticsMeasurable"
      />
    </div>
  </div>
</template>

<script>
  import { LvHeading } from '~~/common/components/loginet-vue-shop/index.mjs';
  import standardComponentsMixin from '~~/common/mixins/standardComponentsMixin';
  import analyticsBannerInteractionMixin from '~~/common/mixins/analyticsBannerInteractionMixin.js';
  import { defineAsyncComponent } from 'vue';

  const isValidCSSTextAlign = v => ['start', 'end', 'left', 'right', 'center', 'justify', 'justify-all', 'match-parent'].includes(v);

  export default {
    name: 'AwLargeBanner',
    components: {
      LvHeading,
      AwTextBlock: defineAsyncComponent(() => import('./AwTextBlock')),
      AwButtons: defineAsyncComponent(() => import('./AwButtons')),
    },
    mixins: [
      standardComponentsMixin,
      analyticsBannerInteractionMixin,
    ],
    props: {
      content: {
        type: Object,
        required: true,
      },
    },
    computed: {
      textAlignment () {
        return isValidCSSTextAlign(this.content.contentAlignment) ? this.content.contentAlignment : null;
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.wrapper {
  position: relative;

  &left {
    align-self: center;
  }

  &center {
    align-self: center;
  }

  &right {
    align-self: center;
  }

  &top {
    align-self: start;
    text-align: center;
    justify-self: center;
  }

  &bottom {
    align-self: end;
    text-align: center;
    justify-self: center;
  }

}

.container {
  align-self: center;
  width: calc(100% - 2 * 48px);
  margin: 24px 48px;
  padding: 24px 0;
  word-break: break-word;

  @include tablet(only) {
    width: calc(100% - 2 * 178px) !important;
    margin: 24px 178px 20px;
    padding: 24px 0 20px;
  }

  @include desktop-medium(min) {
    width: calc(100% - 2 * 100px) !important;
    margin: 24px 100px;
    padding: 32px 0;
  }

  &left {
    justify-self: left;
  }

  &center {
    justify-self: center;
  }

  &right {
    justify-self: right;
  }

  &top {
    align-self: start;
    text-align: center;
    justify-self: center;
  }

  &bottom {
    align-self: end;
    text-align: center;
    justify-self: center;
  }

  .title {
    font-family: $secondary-font;
    font-weight: 400;
    margin: 0 0 40px;
    color: white;
  }

  .titleCarousel {
    font-size: 32px;
    line-height: 40px;

    @include tablet(min) {
      font-size: 56px;
      line-height: 64px;
    }
  }

  .titleNormal {
    font-size: 26px;
    line-height: 40px;

    @include tablet(min) {
      font-size: 48px;
      line-height: 56px;
    }
  }

  .infoText {
    margin: 0 0 32px;
    color: white;
  }

  .infoTextNormal {
    font-size: 16px;
    line-height: 20px;

    @include tablet(min) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  .infoTextCarousel {
    font-size: 18px;
    line-height: 24px;

    @include tablet(min) {
      font-size: 22px;
      line-height: 28px;
    }
  }

  .text {
    margin: 32px 0;

    &BackgroundOnly {
      padding: 24px;
    }
  }
}
</style>
