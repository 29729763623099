<template>
  <div :class="$style.container">
    <div :class="$style.content">
      <lv-image
        v-if="standardComponentContentImage"
        auto-height
        :sources="standardComponentContentImageSource"
        :alt="standardComponentContentImage.alt"
        :loading="isLazyImage ? 'lazy' : 'eager'"
      />
      <div>
        <lv-heading tag="h2" level="5" :style="{color: standardComponentTextColor}">
          {{ content.title }}
        </lv-heading>
        <aw-text-block
          :class="$style.contentText"
          :content="content"
          :analytics-banner-interaction="analyticsBannerInteraction"
          :analytics-measurable="analyticsMeasurable"
        />
        <aw-buttons
          :content="content"
          :button-attrs="{shrink: true, stretch: true}"
          :show-title="false"
          :analytics-banner-interaction="analyticsBannerInteraction"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { LvHeading, LvImage } from '~~/common/components/loginet-vue-shop/index.mjs';
  import standardComponentsMixin from '~~/common/mixins/standardComponentsMixin';
  import analyticsBannerInteractionMixin from '~~/common/mixins/analyticsBannerInteractionMixin.js';
  import { defineAsyncComponent } from 'vue';
  export default {
    name: 'AwBannerWithTextBoxImage',
    components: {
      LvHeading,
      LvImage,
      AwTextBlock: defineAsyncComponent(() => import('./AwTextBlock')),
      AwButtons: defineAsyncComponent(() => import('./AwButtons')),
    },
    mixins: [standardComponentsMixin, analyticsBannerInteractionMixin],
    props: {
      content: {
        type: Object,
        required: true,
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.container {
  align-self: center;
  justify-self: center;
  padding: 24px;

  @include md(min) {
    padding: 24px 120px;
  }

  .content {
    display: grid;
    align-items: center;
    padding: 32px;
    border-radius: 8px;
    background: white;
    grid-template-columns: repeat(1, auto);
    gap: 10px;

    @include md(min) {
      gap: 36px;
      grid-template-columns: repeat(2, auto);
    }

    &Text {
      margin: 16px 0;

      @include md(min) {
        margin: 32px 0 24px;
      }
    }
  }
}
</style>
