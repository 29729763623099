<template>
  <div>
    <div :class="[$style.content, $style[`${content.contentAlignment}`], { [$style.withSidebar]: hasSidebar }]">
      <lv-image
        v-if="standardComponentContentImage"
        :class="[$style.contentPicture, { [$style.contentPictureWide]: content.contentAlignment === 'top' || content.contentAlignment === 'bottom' }]"
        auto-height
        :sources="standardComponentContentImageSource"
        :loading="isLazyImage ? 'lazy' : 'eager'"
        :alt="standardComponentContentImage.alt"
      />
      <div style="grid-area: content;" :class="$style.contentWrapper">
        <lv-heading
          v-if="content.title"
          tag="h3"
          level="3"
          :style="{color: standardComponentTextColor}"
          version2
        >
          {{ content.title }}
        </lv-heading>
        <div :class="$style.contentSubTitleWrapper">
          <lv-heading
            v-if="content.infoText"
            tag="div"
            level="6"
            :style="{color: standardComponentTextColor}"
            regular
          >
            {{ content.infoText }}
          </lv-heading>
        </div>
        <aw-text-block
          :class="[$style.contentText, {[$style.contentTextWithMarginTop]: content.title || content.infoText}]"
          :content="content"
          :analytics-banner-interaction="analyticsBannerInteraction"
          :analytics-measurable="analyticsMeasurable"
        />
        <aw-buttons
          :content="content"
          :button-attrs="{shrink: true, stretch: true}"
          :show-title="false"
          :analytics-banner-interaction="analyticsBannerInteraction"
          :analytics-measurable="analyticsMeasurable"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { LvHeading, LvImage } from '~~/common/components/loginet-vue-shop/index.mjs';
  import standardComponentsMixin from '~~/common/mixins/standardComponentsMixin';
  import analyticsBannerInteractionMixin from '~~/common/mixins/analyticsBannerInteractionMixin.js';
  import { defineAsyncComponent } from 'vue';

  export default {
    name: 'AwImageAndTextBar',
    components: {
      LvHeading,
      LvImage,
      AwTextBlock: defineAsyncComponent(() => import('./AwTextBlock')),
      AwButtons: defineAsyncComponent(() => import('./AwButtons')),
    },
    mixins: [
      standardComponentsMixin,
      analyticsBannerInteractionMixin,
    ],
    props: {
      content: {
        type: Object,
        required: true,
      },
      hasSidebar: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
@mixin grid-with-8-columns {
  grid-template-columns: repeat(8, minmax(0, 1fr));
  grid-template-areas: "picture picture picture content content content content content";

  &.left {
    grid-template-areas: "content content content content content picture picture picture";
  }

  &.right {
    grid-template-areas: "picture picture picture content content content content content";
  }

  &.top {
    grid-template-areas:
      "content content content content content content content content"
      "picture picture picture picture picture picture picture picture";
  }

  &.bottom {
    grid-template-areas:
      "picture picture picture picture picture picture picture picture"
      "content content content content content content content content";
  }
}

.content {
  display: grid;
  align-items: start;
  justify-content: center;
  background: white;
  gap: 24px;
  grid-template-areas: "content" "picture";

  &.right, &.bottom {
    grid-template-areas: "picture" "content";
  }

  @include tablet(min) {
    @include grid-with-8-columns();
  }

  @include desktop-small(min) {
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-template-areas: "picture picture picture picture picture content content content content content content content";
    gap: 32px;

    &.left {
      grid-template-areas: "content content content content content content content picture picture picture picture picture";
    }

    &.right {
      grid-template-areas: "picture picture picture picture picture content content content content content content content";
    }

    &.top {
      grid-template-areas:
        "content content content content content content content content content content content content"
        "picture picture picture picture picture picture picture picture picture picture picture picture";
    }

    &.bottom {
      grid-template-areas:
        "picture picture picture picture picture picture picture picture picture picture picture picture"
        "content content content content content content content content content content content content";
    }
  }

  @include desktop-medium(min) {
    grid-template-columns: repeat(10, minmax(0, 1fr));
    grid-template-areas: "picture picture picture picture content content content content content content";
    gap: 40px;

    &.left {
      grid-template-areas: "content content content content content content picture picture picture picture";
    }

    &.right {
      grid-template-areas: "picture picture picture picture content content content content content content";
    }

    &.top {
      grid-template-areas:
        "content content content content content content content content content content"
        "picture picture picture picture picture picture picture picture picture picture";
    }

    &.bottom {
      grid-template-areas:
        "picture picture picture picture picture picture picture picture picture picture"
        "content content content content content content content content content content";
    }

    &.withSidebar {
      @include grid-with-8-columns();
    }
  }

  &Picture {
    grid-area: picture;
    border-radius: 24px;

    img {
      object-fit: cover;
    }
  }

  &Text {
    margin-top: 0;

    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }

    @include tablet(min) {
      p {
        font-size: 16px;
        line-height: 24px;
      }
    }

    &WithMarginTop {
      margin-top: 20px;
    }
  }

  &SubTitle {
    &Wrapper {
      display: flex;
      align-items: center;
    }
  }
}
</style>
