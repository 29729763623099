<template>
  <div :class="$style.container">
    <component
      :is="componentAttrs.is"
      v-bind="componentAttrs.attrs"
      :class="[
        $style.content,
        $style[`contentTileView${tileType}`],
        {
          [$style.contentBackgroundFill]: content.backgroundRepeat === 'fill',
          [$style.contentBackgroundRepeat]: content.backgroundRepeat === 'repeat',
          [$style.contentBackgroundFixed]: content.backgroundRepeat === 'fix',
          [$style.contentHorizontalView]: content.horizontalLayout,
          [$style.contentBordered]: bordered,
          [$style.contentHasMinWidth]: hasMinWidth,
        }
      ]"
      :style="{
        backgroundColor: standardComponentBackgroundColor,
        backgroundImage: standardComponentBackgroundImage
      }"
      data-cms-card-content
    >
      <div
        :class="$style.contentImageWrapper"
      >
        <lv-image
          v-if="standardComponentContentImageSource?.length && !hasImageError"
          auto-height
          :sources="standardComponentContentImageSource"
          :loading="isLazyImage ? 'lazy' : 'eager'"
          :alt="standardComponentContentImage.alt"
          :class="[$style.contentImage, {[$style.contentImageHasMinWidth]: hasMinWidth && !bordered }, {[$style.contentImageHasMinWidthAndBordered]: hasMinWidth && bordered }]"
          @image-error="onImageError"
        />
        <div
          v-else-if="content.showPlaceholder"
          :class="$style.contentImageFallback"
        >
          <div
            :class="$style.contentImageFallbackIcon"
          >
            <lv-icon name="picture-32" :size="24" />
          </div>
        </div>
        <div
          v-if="content.tags?.[0]"
          :class="$style.contentImageTag"
        >
          {{ content?.tags?.[0] }}
        </div>
      </div>
      <div :class="$style.contentWrapper">
        <span v-if="content.dateTag" :class="$style.contentDate">
          {{ dateTagContent }}
        </span>
        <lv-heading
          v-if="content.title"
          tag="h2"
          level="3"
          :class="$style.contentTitle"
          :style="{color: standardComponentTextColor}"
          version2
        >
          {{ content.title }}
        </lv-heading>
        <aw-text-block
          :class="$style.contentText"
          :content="content"
          :analytics-banner-interaction="analyticsBannerInteraction"
          :analytics-measurable="analyticsMeasurable"
        />
        <aw-buttons
          :content="content"
          :button-attrs="{shrink: true, stretch: true}"
          :show-title="false"
          :class="$style.contentButtons"
          :analytics-banner-interaction="analyticsBannerInteraction"
          :analytics-measurable="analyticsMeasurable"
        />
      </div>
    </component>
  </div>
</template>

<script>
  import { LvHeading, LvImage, LvIcon, LvButton } from '~~/common/components/loginet-vue-shop/index.mjs';
  import standardComponentsMixin from '~~/common/mixins/standardComponentsMixin';
  import analyticsBannerInteractionMixin from '~~/common/mixins/analyticsBannerInteractionMixin.js';
  import { defineAsyncComponent } from 'vue';

  export default {
    name: 'AwCmsCard',
    components: {
      LvHeading,
      LvImage,
      AwTextBlock: defineAsyncComponent(() => import('./AwTextBlock')),
      AwButtons: defineAsyncComponent(() => import('./AwButtons')),
      LvIcon,
    },
    mixins: [
      standardComponentsMixin,
      analyticsBannerInteractionMixin,
    ],
    props: {
      content: {
        type: Object,
        required: true,
      },
      tileType: {
        type: String,
        validator: type => ['', 'full', 'half', 'verticalTile'].includes(type),
        default: '',
      },
      bordered: {
        type: Boolean,
        default: false,
      },
      hasMinWidth: {
        type: Boolean,
        default: true,
      },
      fullComponentLink: {
        type: String,
        default: undefined,
      },
    },
    data () {
      return {
        hasImageError: false,
      };
    },
    computed: {
      dateTagContent () {
        if (!this.content?.dateTag) {
          return null;
        }
        const date = new Date(this.content?.dateTag);
        return this.$date(date, {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }, {
          removeSpaces: false,
        });
      },
      componentAttrs () {
        return this.fullComponentLink
          ? {
            is: LvButton,
            attrs: {
              ...this.$hyperlinkComputeBindFromUrl(this.fullComponentLink),
              stretch: true,
              styling: 'reset',
            },
          }
          : {
            is: 'div',
            attrs: null,
          }
        ;
      },
    },
    methods: {
      onImageError () {
        this.hasImageError = true;
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.container {
  .content {
    text-align: left;
    display: grid;
    align-content: space-between;
    height: 100%;
    padding: 0 24px 24px;
    border-radius: 16px;
    background: white;

    //!important is important to override lv-button/.link-simple:hover {background: transparent;} css rule
    background-position: top center !important;
    background-size: auto !important;
    grid-template-columns: repeat(1, auto);
    grid-template-rows: max-content 1fr;
    gap: 24px;
    object-position: top center !important;

    @include mobile(max) {
      align-content: flex-start;
      grid-template-rows: 144px 1fr;
    }

    &HasMinWidth {
      @include mobile(max) {
        width: 200px;
      }
    }

    &Bordered {
      border: 1px solid $color-border;
    }

    &BackgroundFill, &BackgroundFixed {
      background-repeat: no-repeat !important;
      background-attachment: initial !important;
    }

    &BackgroundFill {
      background-size: 100% 100% !important;
    }

    &BackgroundRepeat {
      background-repeat: repeat !important;
    }

    &BackgroundFixed {
      background-position: center !important;
      background-size: cover !important;
      object-position: center !important;
    }

    @mixin generateHorizonalView() {
      grid-template-columns: repeat(2, auto);
      grid-template-rows: auto;
      padding: 0;
      gap: 24px;

      .contentWrapper {
        padding: 24px 24px 24px 0;
      }

      .contentImage {
        width: 100%;
        height: auto;
        margin: 0;

      }

      .contentTitle {
        font-size: 22px;
        font-weight: $font-weight-bold-v2;
        line-height: 28px;
        color: $color-text-primary;
      }

      .contentText {
        font-size: 14px;
        line-height: 20px;
      }

      .contentButtons {
        /* no op */
      }
    }

    &HorizontalView {
      @include tablet(min) {
        @include generateHorizonalView();
      }
    }

    &TileViewfull, &TileViewhalf {
      @include generateHorizonalView();
    }

    &Wrapper {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: max-content;
      gap: 8px;
      height: 100%;
    }

    &Image {
      overflow: hidden;
      width: calc(100% + 48px);
      height: 100% !important;
      margin: 0 -24px;
      border-radius: 16px;

      @include mobile(max) {
        height: 144px;
      }

      &HasMinWidth {
        @include mobile(max) {
          width: 200px;

          &AndBordered {
            width: 198px !important;
          }
        }
      }

      &Wrapper {
        position: relative;
      }

      img {
        object-fit: cover;
      }

      &Fallback {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        border: 1px solid $color-border;
        border-radius: 16px;
        background-color: $color-background-3;

        &Icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          padding: 8px;
          color: $color-cloud;
          border-radius: 16px;
          background-color: $color-background-1;
        }
      }
    }

    &ImageTag {
      font-family: $secondary-font;
      font-size: 12px;
      font-weight: 700;
      position: absolute;
      bottom: 8px;
      left: 8px;
      padding: 6px 8px;
      color: $color-text-primary;
      border-radius: 10px;
      background-color: rgba($color-background-1, 0.85);
    }

    &Date {
      font-size: 12px;
      font-weight: 500;
      color: $color-text-secondary;
    }

    &Title {
      min-height: 56px;
      word-break: break-word;
      hyphens: auto;
    }

    &Text {
      min-height: 40px;
      word-break: break-word;
      hyphens: auto;
      color: $color-text-secondary;

      p {
        font-size: 12px;
        font-weight: $font-weight-regular-v2;
        line-height: 16px;
        @include tablet(min) {
          font-size: 14px;
          font-weight: $font-weight-normal-v2;
          line-height: 20px;
        }
      }
    }

    &Buttons {
      display: flex;
      align-self: end;
      margin-top: 16px;
    }

    &TileView {
      &full {
        grid-template-columns: 202px auto;
        grid-template-rows: minmax(180px, max-content);

        .contentTitle, .contentText {
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
        }

        .contentWrapper {
          grid-template-rows: 28px 40px auto;
        }

        .contentTitle {
          height: 28px;
          min-height: 28px;
          -webkit-line-clamp: 1;
        }

        .contentText {
          height: 40px;
          -webkit-line-clamp: 2;
        }

        .contentImage {
          width: 202px !important;

          &Wrapper {
            width: 202px !important;
          }
        }

        @include desktop-medium(min) {
          grid-template-columns: 280px auto;
          grid-template-rows: minmax(180px, max-content);

          .contentImage {
            width: 280px !important;

            &Wrapper {
              width: 280px !important;
            }
          }
        }
      }

      &half {
        .contentTitle, .contentText {
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
        }

        .contentTitle {
          height: 56px;
          -webkit-line-clamp: 2;
        }

        .contentText {
          height: 60px;
          -webkit-line-clamp: 3;
        }

        @include desktop-small(only) {
          grid-template-columns: 202px auto;
          grid-template-rows: minmax(228px, 1fr);

          .contentImage {
            width: 202px !important;
            height: 100% !important;

            &Wrapper {
              width: 202px !important;
              height: 100% !important;
            }
          }
        }

        @include desktop-medium(min) {
          grid-template-columns: minmax(auto, 280px) auto;
          grid-template-rows: minmax(228px, 1fr);

          .contentImage {
            width: 100% !important;
            height: 100% !important;
            min-height: 228px;

            &Wrapper {
              width: 100% !important;
              height: 100% !important;
              min-height: 228px;
            }
          }
        }

        @include desktop-large(min) {
          grid-template-columns: 280px auto;

          .contentImage {
            width: 280px !important;
            height: 100% !important;
            min-height: 228px;

            &Wrapper {
              width: 280px !important;
              height: 100% !important;
              min-height: 228px;
            }
          }
        }
      }

      &verticalTile {
        grid-template-rows: 144px auto;

        .contentImage {
          height: 144px !important;

          &Wrapper {
            height: 144px !important;
          }

          &Fallback {
            margin-right: -24px;
            margin-left: -24px;
          }

          &Tag {
            left: -16px;
          }
        }

        .contentTitle, .contentText {
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
        }

        .contentTitle {
          height: 48px;
          min-height: 48px;
          -webkit-line-clamp: 2;
          @include tablet(min) {
            height: 56px;
          }
        }

        .contentText {
          height: 48px;
          -webkit-line-clamp: 3;

          @include tablet(min) {
            height: 60px;
          }
        }

        .contentButtons {
          margin-top: 8px;
        }
      }
    }
  }
}
</style>
