<template>
  <div :class="$style.container">
    <div
      :class="[$style.content,
               {[$style.contentBackgroundFill]: content.backgroundRepeat === 'fill'},
               {[$style.contentBackgroundRepeat]: content.backgroundRepeat === 'repeat'},
               {[$style.contentBackgroundFixed]: content.backgroundRepeat === 'fix'}
      ]"
      :style="{
        backgroundColor: standardComponentBackgroundColor,
        backgroundImage: standardComponentBackgroundImage
      }"
    >
      <lv-image
        v-if="standardComponentContentImage"
        auto-height
        :sources="standardComponentContentImageSource"
        :alt="standardComponentContentImage.alt"
        :class="$style.contentImage"
        :loading="isLazyImage ? 'lazy' : 'eager'"
      />
      <lv-heading
        v-if="content.title"
        tag="h2"
        level="5"
        :class="$style.contentTitle"
        :style="{color: standardComponentTextColor}"
      >
        {{ content.title }}
      </lv-heading>
      <aw-text-block
        :class="$style.contentText"
        :content="content"
        :analytics-banner-interaction="analyticsBannerInteraction"
        :analytics-measurable="analyticsMeasurable"
      />
      <aw-buttons
        :content="content"
        :button-attrs="{shrink: true, stretch: true}"
        :show-title="false"
        :analytics-banner-interaction="analyticsBannerInteraction"
        :analytics-measurable="analyticsMeasurable"
      />
    </div>
  </div>
</template>

<script>
  import { LvHeading, LvImage } from '~~/common/components/loginet-vue-shop/index.mjs';
  import standardComponentsMixin from '~~/common/mixins/standardComponentsMixin';
  import analyticsBannerInteractionMixin from '~~/common/mixins/analyticsBannerInteractionMixin.js';
  import { defineAsyncComponent } from 'vue';

  export default {
    name: 'AwImageTextLinkColumns',
    components: {
      LvHeading,
      LvImage,
      AwTextBlock: defineAsyncComponent(() => import('./AwTextBlock')),
      AwButtons: defineAsyncComponent(() => import('./AwButtons')),
    },
    mixins: [
      standardComponentsMixin,
      analyticsBannerInteractionMixin,
    ],
    props: {
      content: {
        type: Object,
        required: true,
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.container {
  .content {
    display: grid;
    align-content: space-between;
    height: 100%;
    padding: 0 24px 24px;
    text-align: center;
    background: white;

    //!important is important to override lv-button/.link-simple:hover {background: transparent;} css rule
    background-position: top center !important;
    background-size: auto !important;
    grid-template-columns: repeat(1, auto);
    object-position: top center !important;

    &BackgroundFill, &BackgroundFixed {
      background-repeat: no-repeat !important;
      background-attachment: initial !important;
    }

    &BackgroundFill {
      background-size: 100% 100% !important;
    }

    &BackgroundRepeat {
      background-repeat: repeat !important;
    }

    &BackgroundFixed {
      background-position: center !important;
      background-size: cover !important;
      object-position: center !important;
    }

    &Image {
      width: calc(100% + 48px);
      margin: 0 -24px;
    }

    &Title {
      padding-top: 24px;
    }

    &Text {
      margin: 16px 0;

      @include md(min) {
        margin: 24px 0;
      }
    }
  }
}
</style>
