<template>
  <div :class="$style.container" :style="{'justify-self': content.contentAlignment}">
    <lv-heading
      v-if="content.title"
      :class="$style.title"
      tag="h2"
      level="1"
      :style="{'text-align': content.contentAlignment,color: standardComponentTextColor }"
    >
      {{ content.title }}
    </lv-heading>
    <lv-heading
      v-if="content.infoText"
      :class="$style.subTitle"
      tag="div"
      level="6"
      :style="{'text-align': content.contentAlignment, color: standardComponentTextColor}"
      regular
    >
      {{ content.infoText }}
    </lv-heading>
    <aw-buttons
      :content="content"
      :show-title="false"
      :analytics-banner-interaction="analyticsBannerInteraction"
      :analytics-measurable="analyticsMeasurable"
    />
  </div>
</template>

<script>
  import { LvHeading } from '~~/common/components/loginet-vue-shop/index.mjs';
  import standardComponentsMixin from '~~/common/mixins/standardComponentsMixin';
  import analyticsBannerInteractionMixin from '~~/common/mixins/analyticsBannerInteractionMixin.js';
  import { defineAsyncComponent } from 'vue';

  export default {
    name: 'AwTitleBannerWithSubtitle',
    components: {
      LvHeading,
      AwButtons: defineAsyncComponent(() => import('./AwButtons')),
    },
    mixins: [
      standardComponentsMixin,
      analyticsBannerInteractionMixin,
    ],

    props: {
      content: {
        type: Object,
        required: true,
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.container {
  align-self: center;

  padding: 24px;
  text-align: center;
  @include sm(min) {
    padding: 24px 120px;
  }

  .title, .subTitle {
    text-align: center;
    color: white;
  }

  .subTitle {
    margin: 16px 0 24px;
    padding: 24px;
    background: rgba(0, 0, 0, 0.6);

    @include sm(min) {
      margin: 32px 0;
    }
  }
}
</style>
