<template>
  <div :class="$style.container">
    <div :class="$style.content">
      <lv-heading
        v-if="content.title"
        tag="h2"
        level="5"
        :style="{color: standardComponentTextColor}"
      >
        {{ content.title }}
      </lv-heading>
      <aw-text-block
        :class="$style.contentText"
        :content="content"
        :analytics-banner-interaction="analyticsBannerInteraction"
        :analytics-measurable="analyticsMeasurable"
      />
      <aw-buttons
        :content="content"
        :button-attrs="{shrink: true, stretch: true}"
        :show-title="false"
        :analytics-banner-interaction="analyticsBannerInteraction"
      />
    </div>
  </div>
</template>

<script>
  import { LvHeading } from '~~/common/components/loginet-vue-shop/index.mjs';
  import standardComponentsMixin from '~~/common/mixins/standardComponentsMixin';
  import analyticsBannerInteractionMixin from '~~/common/mixins/analyticsBannerInteractionMixin.js';
  import { defineAsyncComponent } from 'vue';

  export default {
    name: 'AwBannerWithTextBox',
    components: {
      LvHeading,
      AwTextBlock: defineAsyncComponent(() => import('./AwTextBlock')),
      AwButtons: defineAsyncComponent(() => import('./AwButtons')),
    },
    mixins: [
      standardComponentsMixin,
      analyticsBannerInteractionMixin,
    ],
    props: {
      content: {
        type: Object,
        required: true,
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.container {
  align-self: center;
  justify-self: flex-start;
  padding: 24px;

  @include md(min) {
    padding: 24px 120px;
  }

  .content {
    padding: 32px;
    border-radius: 8px;
    background: white;

    @include md(min) {
      max-width: 50%;
    }

    &Text {
      margin: 16px 0;

      @include md(min) {
        margin: 32px 0 24px;
      }
    }
  }
}
</style>
