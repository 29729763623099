<template>
  <div :class="[$style.container, $style[`container${content.layoutType}`]]">
    <div :class="$style.content">
      <div style="grid-area: title;" :class="$style.titleWrapper">
        <span v-if="content.title" :class="$style.title" :style="{color: standardComponentTextColor}">
          {{ content.title }}
        </span>
        <span v-if="content.infoText" :class="$style.subTitle">
          {{ content.infoText }}
        </span>
      </div>
      <lv-image
        v-if="standardComponentContentImage"
        auto-height
        :sources="standardComponentContentImageSource"
        :alt="standardComponentContentImage.alt"
        :class="$style.image"
        style="grid-area: image;"
        :loading="isLazyImage ? 'lazy' : 'eager'"
      />
      <aw-text-block
        :class="$style.text"
        :content="content"
        style="grid-area: text;"
        :analytics-banner-interaction="analyticsBannerInteraction"
        :analytics-measurable="analyticsMeasurable"
      />
      <aw-buttons
        :content="content"
        :button-attrs="{shrink: true, stretch: true}"
        style="grid-area: buttons;"
        :show-title="false"
        :analytics-banner-interaction="analyticsBannerInteraction"
        :analytics-measurable="analyticsMeasurable"
      />
    </div>
  </div>
</template>

<script>
  import { LvImage } from '~~/common/components/loginet-vue-shop/index.mjs';
  import standardComponentsMixin from '~~/common/mixins/standardComponentsMixin';
  import analyticsBannerInteractionMixin from '~~/common/mixins/analyticsBannerInteractionMixin.js';
  import { defineAsyncComponent } from 'vue';

  export default {
    name: 'AwTeamMemberPartnerCustomerReview',
    components: {
      LvImage,
      AwTextBlock: defineAsyncComponent(() => import('./AwTextBlock')),
      AwButtons: defineAsyncComponent(() => import('./AwButtons')),
    },
    mixins: [
      standardComponentsMixin,
      analyticsBannerInteractionMixin,
    ],
    props: {
      content: {
        type: Object,
        required: true,
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.container {
  align-self: center;
  justify-self: center;
  padding: 24px;

  &full {
    @include md(min) {
      padding: 24px 120px;
    }
  }

  .content {
    display: grid;
    margin: 0 auto;
    padding: 24px;
    background: white;
    grid-template-columns: 124px repeat(2, auto);
    gap: 16px;

    grid-template-areas:
        "image title title"
        "text text text"
        "buttons buttons buttons";

    @include md(min) {
      padding: 32px;
      grid-template-columns: repeat(4, minmax(0, auto));
      grid-template-areas:
        "title title title title"
        "image text text text"
        "buttons buttons buttons buttons";

    }
  }

  .titleWrapper {
    display: flex;
    flex-flow: column;

    justify-content: center;

    @include md(min) {
      flex-flow: row;
      justify-content: flex-start;
    }
  }

  .title {
    margin-right: 4px;
    color: $color-brand-primary;
  }

  .subTitle, .title {
    font-size: 14px;
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .image {
    max-width: 124px;
    max-height: 124px;
    border-radius: 50%;
  }

  .text {
    display: flex;
    align-items: center;
    margin: 16px 0;
  }
}
</style>
